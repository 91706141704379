<template>
  <div class="page page__leistungen">
    <h1>coming soon</h1>
    <TheFooter class="section" />
  </div>
</template>

<script>
// import LeistungsBlock from "@/components/common/leistungen/LeistungsBlock.vue";
// import SDDBlockLeistung from "@/components/common/sdd/SDDBlockLeistung.vue";
// import GalleryProjekts from "@/components/common/gallery/GalleryProjekts.vue";
// import KundenBlock from "@/components/common/kunden/KundenBlock.vue";
// import ContactBlock from "@/components/common/contact/ContactBlock.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { useStore } from "vuex";

export default {
  components: {
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
